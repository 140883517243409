<template>
  <div class="window" :class="{point: $store.state.onboarding}" @wheel="zoom">
      <chefAbout/>
      <div class="topFrame"></div>
      <div class="sideFrame"></div>
      <div class="topNavigator"  :style="{width: `${(160 - $store.state.scaleFactor) / 2}vw`, transform: `translateX(${navigateX}px)`}"></div>
      <div class="sideNavigator"  :style="{height: `${(160 - $store.state.scaleFactor) / 2}vh`, transform: `translateY(${navigateY}px)`}"></div>
      <transition name="onboarding">
        <ChefOnboarding  v-if="$store.state.onboarding"/>
      </transition>
      <div class="preventMobile" v-if="$store.state.mobile">
        <h3>Chef's Memory is not avaiable for mobile devices yet.</h3>
        <svg viewBox="0 0 150 107" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 2.5H147.5V104.5H2.5V2.5Z" fill="#FCFBF5" stroke="#362020" stroke-width="4"/>
          <rect x="2.5" y="2.5" width="144.563" height="19.2117" fill="#FCFBF5" stroke="#362020" stroke-width="4"/>
          <circle cx="64" cy="48" r="5" fill="#ED5468"/>
          <circle cx="87" cy="48" r="5" fill="#ED5468"/>
          <path d="M48 78C48 78 53.0354 69 74.4906 69C95.9458 69 102 78 102 78" stroke="#ED5468" stroke-width="4" stroke-linecap="round"/>
        </svg>
        <h4>To visit the website please change to your desktop device.</h4>
      </div>
      <div class="navigation">
        <div class="left">
          <div class="legend">
            <p class="description">Zoom:</p>
            <div class="legendWrapper">
              <p class="legendButton" :class="{active: $store.state.pressedKey === 'Meta' || $store.state.pressedKey === 'Control'}">Cmd/Ctrl</p>
              <p>+ Scroll</p>
            </div>
          </div>
          <div class="legend">
            <p class="description">Navigation:</p>
            <div class="legendWrapper">
              <p class="legendButton" :class="{active: $store.state.pressedKey === ' '}">Space</p>
              <p>+ Drag</p>
            </div>
          </div>
        </div>
        <div class="right">
          <chefButtonRounded :buttonText="`${checkChaos.charAt(0).toUpperCase() + checkChaos.slice(1)}`" @click="orderChaos"/>
          <chefButtonRounded buttonText="Center"  @click="centerView"/>
          <chefButtonRounded buttonText="About"  @click="toggleAbout"/>
        </div>
      </div>
      <div class="scaleWrapper" :class="{zooming}" :style="{transform: `scale(${$store.state.scaleFactor/100})`}">
        <div class="cards" :class="{drag: dragging,  noScroll: zooming, moveback: center}" @mousedown="dragStart" @wheel="startScroll" :style="{transform: `translate(${this.dragX}px, ${this.dragY}px)`}">
          <ChefCard
          v-for="(card,index) in cards"
          :key ="card.title"
          :content="card"
          :index="index"
          />
        </div>
      </div>
  </div>
</template>

<script>
import ChefCard from '@/components/ChefCard.vue';
import ChefAbout from '@/components/ChefAbout.vue';
import ChefOnboarding from '@/components/ChefOnboarding.vue';
import ChefButtonRounded from '@/components/ChefButtonRounded.vue';

import aroma from '@/data/aroma';
import salt from '@/data/salt';
import sweetness from '@/data/sweetness';
import texture from '@/data/texture';
import flavourpairing from '@/data/flavourpairing';
import fat from '@/data/fat';
import seasonal from '@/data/seasonal';
import balance from '@/data/balance';
import taste from '@/data/taste';
import nationality from '@/data/nationality';
import fry from '@/data/fry';
import oven from '@/data/oven';
import cooking from '@/data/cooking';
import salting from '@/data/salting';

export default {
  created() {
    // console.log('HomeCreated');
    this.$store.commit('setNumberOfCards', this.cards.length);
    this.highestIndex = 20;
    // Transforming Canvas and Set start DragX and DragY
    const centerHeight = window.innerHeight / 2;
    const centerWidth = window.innerWidth / 2;
    const canvasSizeCenter = 10000 / 2;
    const REMBASE = this.$store.state.rembase;
    // console.log(centerHeight);
    const backGroundGridSize = (50 / 16) * REMBASE;
    this.dragX = (Math.floor((centerWidth - ((canvasSizeCenter / 16) * REMBASE)) / backGroundGridSize) * backGroundGridSize);
    this.dragY = (Math.floor((centerHeight - ((canvasSizeCenter / 16) * REMBASE)) / backGroundGridSize) * backGroundGridSize);

    this.$store.commit('setNormalsX', this.dragX * (-1));
    this.$store.commit('setNormalsY', this.dragY * (-1));

    // console.log('New normals for currentCanvas');
    // console.log(this.$store.state.normalX);
    // console.log(this.$store.state.normalY);

    // Create the Order array
    // Für jedes Element im Array setze die X und y Coordinaten in das array
  },
  mounted() {
    const REMBASE = this.$store.state.rembase;
    // Set Window Position to first card
    this.dragX = this.$store.state.firstCardX + (((700 / 16) * this.$store.state.rembase) / 2);
    this.dragY = this.$store.state.firstCardY + (((480 / 16) * this.$store.state.rembase) / 2);
    // console.log('Home Mounted');
    // console.log(this.dragX);
    // console.log(this.dragY);

    const orderArray = [];
    const colGap = (100 / 16) * REMBASE;
    const cardWidth = (700 / 16) * REMBASE;
    const cardHeight = (500 / 16) * REMBASE;
    const xStart = (this.dragX * (-1)) + (((700 / 16) * this.$store.state.rembase) / 2);
    const yStart = (this.dragY * (-1)) + (((480 / 16) * this.$store.state.rembase) / 2);
    let currcColum = 0;
    let lastCardTopic = this.cards[0].topic;
    let row = 0;

    for (let i = 0; i < this.cards.length; i += 1) {
      if (this.cards[i].topic === lastCardTopic) {
        orderArray.push({ x: xStart + (currcColum * (cardWidth + colGap)), y: yStart + (row * (cardHeight + colGap)) });
        row += 1;
      } else {
        lastCardTopic = this.cards[i].topic;
        currcColum += 1;
        row = 1;
        orderArray.push({ x: xStart + (currcColum * (cardWidth + colGap)), y: yStart });
      }
    }
    // console.log(orderArray);
    this.$store.commit('setGrid', orderArray);
    // console.log(this.$store.state.gridPositions);
  },
  components: {
    ChefCard,
    ChefAbout,
    ChefOnboarding,
    ChefButtonRounded,
  },
  computed: {
    checkChaos() {
      if (!this.$store.state.chaos) {
        return 'chaos';
      }
      return 'order';
    },
    zooming() {
      return this.$store.state.zoom;
    },
    navigateX() {
      const windowWidth = window.innerWidth;
      const width = (((160 - this.$store.state.scaleFactor) / 2) * windowWidth) / 100;
      // // console.log(width);
      const distance = (windowWidth - width) / 2;
      const NORMALX = this.$store.state.normalX;
      const Xpercent = (this.dragX / NORMALX) * -1;
      // console.log(Xpercent);
      return (distance * Xpercent) + 50;
    },
    navigateY() {
      const windowHeight = window.innerHeight;
      const height = (((160 - this.$store.state.scaleFactor) / 2) * windowHeight) / 100;
      // // console.log(width);
      const distance = (windowHeight - height) / 2;
      const NORMALY = this.$store.state.normalY;
      const Ypercent = (this.dragY / NORMALY) * -1;
      // // console.log(Xpercent);
      return (distance * Ypercent) + 50;
    },
  },
  data() {
    return {
      center: false,
      isCenter: null,
      onboarding: true,
      isScrolling: null,
      scrolling: false,
      dragging: false,
      dragVelocity: 0,
      dragVelocityX: 0,
      dragVelocityY: 0,
      dragProc: 0,
      dragX: 0,
      dragY: 0,
      momentumID: 0,
      velocityArrayX: [],
      velocityArrayY: [],
      deltaX: 0,
      deltaY: 0,
      stratX: 0,
      startY: 0,
      highestIndex: 0,
      gridPositions: [],
      cards: [
        // Importierte data js Dateien.
        aroma,
        texture,
        flavourpairing,
        salt,
        sweetness,
        fat,
        seasonal,
        balance,
        nationality,
        taste,
        fry,
        oven,
        cooking,
        salting,
      ],
    };
  },
  methods: {
    centerView() {
      this.center = true;
      this.dragX = this.$store.state.firstCardX + (((700 / 16) * this.$store.state.rembase) / 2);
      this.dragY = this.$store.state.firstCardY + (((480 / 16) * this.$store.state.rembase) / 2);
      window.clearTimeout(this.isCenter);
      this.isCenter = setTimeout(() => {
        console.log('Scrolling Stopped');
        this.center = false;
      }, 600);
    },
    orderChaos() {
      this.$store.commit('changeChaosMode');
      // console.log(this.$store.state.chaos);
    },
    toggleAbout() {
      this.$store.commit('toggleAbout');
      // console.log(this.$store.state.toggleAbout);
    },
    zoom(e) {
      if (e.ctrlKey || e.metaKey) {
        this.$store.commit('setZoom', true);
        const delta = e.deltaY;
        let currentScaling = this.$store.state.scaleFactor / 100;
        // console.log('startwert');
        // console.log(currentScaling);

        e.preventDefault();
        currentScaling += delta * 0.007;
        currentScaling = Math.min(Math.max(0.125, currentScaling), 1.5);
        this.$store.commit('setScaleFactor', currentScaling * 100);
      } else {
        this.$store.commit('setZoom', false);
      }
    },
    startScroll(e) {
      if (this.zooming) return;

      if (e.target.classList.contains('cards') && this.$store.state.pressedKey !== 'Meta') this.scrolling = true;
      // // console.log(e.target.classList);
      if (!e.target.classList.contains('cards') && !this.scrolling) return;
      // if (!e.target.classList.contains('cards') || this.$store.state.pressedKey === 'Meta') return;
      e.preventDefault();
      this.cancelMomentumTracking();

      const clientX = e.deltaX;
      const clientY = e.deltaY;

      this.dragX -= clientX;
      this.dragY -= clientY;

      const CANVASWIDTH = ((10000 / 16) * this.$store.state.rembase);
      const CANVASHEIGHT = CANVASWIDTH;
      if (this.dragX > -50) {
        this.dragX = -50;
      }
      if (this.dragY > -50) {
        this.dragY = -50;
      }
      if (Math.abs(this.dragX) >= CANVASWIDTH - window.innerWidth - 800) {
        this.dragX = (CANVASWIDTH - window.innerWidth - 800) * -1;
        // console.log('rightEnd');
      }
      // console.log(this.dragX);
      if (Math.abs(this.dragY) >= (CANVASHEIGHT - 2000)) {
        this.dragY = (CANVASHEIGHT - 2000) * (-1);
      }
      // Rekursive Funktion checkt ob noch gescrollt wird.
      window.clearTimeout(this.isScrolling);
      this.isScrolling = setTimeout(() => {
        console.log('Scrolling Stopped');
        this.scrolling = false;
      }, 66);
    },
    dragStart(e) {
      if (e.target.classList.contains('cards') || this.$store.state.pressedKey === ' ') {
        this.dragging = true;
        this.startX = e.clientX;
        this.startY = e.clientY;
        window.addEventListener('mousemove', this.dragUpdate, false);
        window.addEventListener('mouseup', this.dragEnd, false);
      }
      // // console.log('start Dragging:');
    },
    dragUpdate(e) {
      this.deltaX = (e.clientX - this.startX) / (this.$store.state.scaleFactor / 100);
      this.deltaY = (e.clientY - this.startY) / (this.$store.state.scaleFactor / 100);
      this.startX = e.clientX;
      this.startY = e.clientY;
      this.dragX += this.deltaX;
      this.dragY += this.deltaY;
    },
    dragEnd() {
      const CANVASWIDTH = ((10000 / 16) * this.$store.state.rembase);
      const CANVASHEIGHT = CANVASWIDTH;
      this.dragVelocityX = this.deltaX;
      this.dragVelocityY = this.deltaY;
      this.dragging = false;
      window.removeEventListener('mousemove', this.dragUpdate, false);
      window.removeEventListener('mouseup', this.dragEnd, false);
      if (this.dragX > -50) {
        this.dragX = -50;
      }
      if (this.dragY > -50) {
        this.dragY = -50;
      }
      if (Math.abs(this.dragX) >= CANVASWIDTH - window.innerWidth - 1000) {
        this.dragX = (CANVASWIDTH - window.innerWidth - 1000) * -1;
        // console.log('rightEnd');
      }
      // console.log(this.dragX);
      if (Math.abs(this.dragY) >= (CANVASHEIGHT - 2000)) {
        this.dragY = (CANVASHEIGHT - 2000) * (-1);
      }
      if (this.dragX < 0 && this.dragY < 0) {
        this.dragMomentumStart();
      }
    },
    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID);
    },
    dragMomentumStart() {
      this.cancelMomentumTracking();
      this.momentumID = requestAnimationFrame(this.momentumLoop);
    },

    momentumLoop() {
      // // console.log('loop');
      this.dragX += this.dragVelocityX;
      this.dragY += this.dragVelocityY;

      this.dragVelocityX *= 0.86;
      this.dragVelocityY *= 0.86;

      if (Math.abs(this.dragVelocityX) > 0.5 || Math.abs(this.dragVelocityY) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop);
      }
    },
  },
};

</script>

<style lang="stylus">
@require '../styles/styles.styl'
@require '../styles/fonts.styl'
.onboarding-enter-active,
.onboarding-leave-active
  transition: opacity 400ms ease
  // transition: transform 500ms ease
  // transform-origin: top
.onboarding-enter-from,
.onboarding-leave-to
   opacity: 0

.preventMobile
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white
  color: $black
  z-index: 200
  text-align: center
  display: flex
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg
    margin: (30/16)rem auto
    width: (280/16)rem;
  h3
    font-family: 'SangBleuKingdom'
    font-size: (28/16)rem;
    line-height: 2.2 rem
    font-weight: bold
    padding: 0
    max-width: (300/16)rem
    // margin: (8/16)rem 0
  h4
    max-width: (300/16)rem
    // margin: 0 auto
    font-family: 'SuisseIntl'
    font-size: (17/16)rem;
    color: #A39A9A;
    padding-bottom: 3rem

.window
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  overflow: hidden
  display: block
  background-color: $white
  // transition: all 300ms ease
  .navigation
    position: fixed
    bottom: (20/16)rem
    width: 100%
    display: flex
    z-index: 1
    justify-content: space-between
    align-items: flex-end;
    .left
      margin-left: (40/16)rem
      display: flex
      .legend
        .description
          margin: .6rem
          margin-left: .3rem
          font-size: 1rem
          color: #A39A9A
        .legendWrapper
          display: flex
          align-items: baseline;
          margin-right: 3rem
          color: $black
          .legendButton
            background-color: $white
            font-size: 1.1rem
            border: 2px solid $black;
            border-radius: 6 px;
            padding-left: .6rem
            padding-right: .6rem
            padding-top: .3rem
            padding-bottom: .4rem
            &.active
              background-color: $red
              color: $white
          p
            // background-color: $white
            padding: 0
            margin: 0
            font-size: 1.1rem
            margin-left: .3rem
  &.point
    cursor: pointer;
  .topFrame
    position: fixed
    height: (1.4/16)rem
    width: 95vw
    left: "calc(50% - %s)" % (95/2)vw
    background-color: $black
    z-index: 199
    top: 1.25em
  .sideFrame
    position: fixed
    width: (1.4/16)rem
    height: 95vh
    top: "calc(50% - %s)" % (95/2)vh
    background-color: $black
    z-index: 199
    left: 1.25em
  .topNavigator
    position: fixed
    height: 1.3rem
    // background-color: $white
    border-left: (2/16)rem solid
    border-right: (2/16)rem solid
    // border-right: (3/16)rem solid
    z-index: 200
    top: .7rem
  .sideNavigator
    position: fixed
    width: 1.3rem
    // background-color: $white
    border-top: (2/16)rem solid
    border-bottom: (2/16)rem solid
    z-index: 200
    left: .7 rem

  .about
    position: absolute;
    z-index: 200
    left: "calc(50% - %s)" % ((700/2)/16)rem
    top: "calc(50% - %s)" % ((481/2)/16)rem
  .scaleWrapper
    width: 100%
    height: 100%
    // transition: transform 120ms ease-out;
    .cards
      background-image: url("../assets/dot.png");
      background-size: (50/16)rem (50/16)rem
      position: relative;
      width: (10000/16)rem
      height: @width
      &.noScroll
        overflow: hidden;
      &.drag
        cursor: grab;
      &.moveback
        transition: transform 600ms ease
    .thumb
      max-width: 700px
</style>
