import { createStore } from 'vuex';

export default createStore({
  state: {
    numberOfCards: 0,
    zoom: false,
    toggleAbout: false,
    onboarding: true,
    mobile: false,
    rembase: 16,
    highestIndex: 20,
    gridPositions: [],
    chaos: true,
    normalX: 0,
    noramalY: 0,
    scaleFactor: 100,
    firstCardX: 0,
    firstCardY: 0,
    pressedKey: '',
  },
  mutations: {
    setRemBase(state, value) {
      state.rembase = value;
    },
    setHighestIndex(state) {
      state.highestIndex += 1;
    },
    changeChaosMode(state) {
      state.chaos = !state.chaos;
    },
    toggleAbout(state) {
      state.toggleAbout = !state.toggleAbout;
    },
    setNormalsX(state, value) {
      state.normalX = value;
    },
    setNormalsY(state, value) {
      state.normalY = value;
    },
    setGrid(state, value) {
      console.log(value);
      state.gridPositions = value;
    },
    setScaleFactor(state, value) {
      state.scaleFactor = value;
    },
    stopOnboarding(state) {
      state.onboarding = false;
    },
    setNumberOfCards(state, value) {
      state.numberOfCards = value;
    },
    setFirstCardX(state, value) {
      state.firstCardX = value;
    },
    setFirstCardY(state, value) {
      state.firstCardY = value;
    },
    setPressedKey(state, value) {
      state.pressedKey = value;
    },
    setZoom(state, value) {
      state.zoom = value;
    },
    setMobileState(state, value) {
      state.mobile = value;
    },
  },
  actions: {
  },
  modules: {
  },
});
