<template lang="html">
  <div class="lottie">
    <!-- <p>Lottie</p> -->
    <div ref="anim" class="anim" :class="animation"></div>
    <!-- <lottie-animation
    path="../assets/anim/data.json"
    /> -->
  </div>
</template>

<script>

import LottieAnimation from 'lottie-web'; // import lottie-vuejs
import wave from '../assets/anim/wave.json';
import dots from '../assets/anim/dots.json';
import checkered from '../assets/anim/checkered.json';
import isometric from '../assets/anim/isometric.json';
import expand from '../assets/anim/expand.json';
import move from '../assets/anim/move.json';
import nav from '../assets/anim/nav.json';
import scroll from '../assets/anim/scroll.json';

// const waveanim = JSON.parse(wave);

export default {
  beforeUnmount() {
    this.lottie.destroy();
  },
  watch: {
    startAnimation(newValue) {
      // console.log(newValue);
      if (newValue) {
        this.lottie.play();
      } else {
        this.lottie.stop();
      }
    },
    animation(newValue) {
      this.lottie.destroy();
      if (newValue === 'move') this.loadAnimationFile(move);
      if (newValue === 'expand') this.loadAnimationFile(expand);
      if (newValue === 'nav') this.loadAnimationFile(nav);
      if (newValue === 'scroll') this.loadAnimationFile(scroll);
      // console.log(newValue);
    },
  },
  // computed: {
  //   start() {
  //     // console.log(this.startAnimation);
  //     return this.startAnimation;
  //   },
  // },
  mounted() {
    if (this.animation === 'wave') this.loadAnimationFile(wave);
    if (this.animation === 'isometric') this.loadAnimationFile(isometric);
    if (this.animation === 'checkered') this.loadAnimationFile(checkered);
    if (this.animation === 'dots') this.loadAnimationFile(dots);
    if (this.animation === 'move') this.loadAnimationFile(move);
    if (this.animation === 'expand') this.loadAnimationFile(expand);
    if (this.animation === 'nav') this.loadAnimationFile(nav);
    if (this.animation === 'scroll') this.loadAnimationFile(scroll);
    // console.log(this.startAnimation);
  },
  props: {
    startAnimation: {
      type: Boolean,
    },
    animation: {
      type: String,
      default: 'wave',
    },
  },
  data() {
    return {
      wave,
      dots,
      checkered,
      isometric,
      expand,
      move,
      nav,
      scroll,
    };
  },
  methods: {
    loadAnimationFile(animation) {
      this.lottie = LottieAnimation.loadAnimation({ // eslint-disable-line no-var
        container: this.$refs.anim,
        loop: true,
        renderer: 'svg', // Required
        autoplay: true,
        animationData: animation,
      });
      if (this.animation === 'wave' || this.animation === 'dots' || this.animation === 'checkered' || this.animation === 'isometric') this.lottie.stop();
    },
  },
};

//
// lottie.play();

// // console.log(typeof wave);

</script>

<style lang="stylus" scoped>
.lottie
  position: relative
  width: 100%
  height: 100%
  // background-color: red
</style>
