<template lang="html">
  <svg class="icon" fill="none"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73">
    <!-- close -->
    <g v-if="icon === 'close'">
      <g class="lo1">
        <path d="M27.8095 10.4286L27.8095 27.5715L10.4286 27.5715" stroke="currentColor" stroke-width="3"/>
        <path d="M27.8095 27.5715L10.4286 10.4286" stroke="currentColor" stroke-width="3"/>
      </g>
      <g class="ro1">
        <path d="M62.5716 27.5715L45.1907 27.5715L45.1907 10.4286" stroke="currentColor" stroke-width="3"/>
        <path d="M45.1907 27.5715L62.5716 10.4286" stroke="currentColor" stroke-width="3"/>
      </g>
      <g class="ru1">
        <path d="M45.1907 62.5725L45.1907 45.4295L62.5716 45.4295" stroke="currentColor" stroke-width="3"/>
        <path d="M45.1907 45.4295L62.5716 62.5724" stroke="currentColor" stroke-width="3"/>
      </g>
      <g class="lu1">
        <path d="M9.99999 45L27.3809 45L27.3809 62.1429" stroke="currentColor" stroke-width="3"/>
        <path d="M27.3809 45L9.99999 62.1429" stroke="currentColor" stroke-width="3"/>
      </g>
    </g>
    <g v-if="icon === 'open'">
      <g class = "lo">
        <path d="M10.4286 27.5716V10.4286H27.8095" stroke="currentColor" stroke-width="3"/>
        <path d="M10.4286 10.4286L27.8095 27.5716" stroke="currentColor" stroke-width="3"/>
      </g>
      <g class="ro">
        <path d="M45.1906 10.4286H62.5715V27.5716" stroke="currentColor" stroke-width="3"/>
        <path d="M62.5715 10.4286L45.1906 27.5716" stroke="currentColor" stroke-width="3"/>
      </g>
      <g class="ru">
        <path d="M62.5715 45.4294V62.5724H45.1906" stroke="currentColor" stroke-width="3"/>
        <path d="M62.5715 62.5724L45.1906 45.4294" stroke="currentColor" stroke-width="3"/>
      </g>
      <g class = "lu">
        <path d="M27.8093 62.5724H10.4284V45.4294" stroke="currentColor" stroke-width="3"/>
        <path d="M10.4284 62.5724L27.8093 45.4294" stroke="currentColor" stroke-width="3"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'fail',
    },
  },
};
</script>

<style lang="stylus" scoped>
.icon
  color currentColor;
  width: 85%
.icon:hover
  cursor: pointer;
  .lo
    animation: arrowLo 700ms ease-in-out infinite;
    @keyframes arrowLo {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(-3px,-3px);
      }
    }
  .ro
    animation: arrowRo 700ms ease-in-out infinite;
    @keyframes arrowRo {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(3px,-3px);
      }
    }
  .ru
    animation: arrowRu 700ms ease-in-out infinite;
    @keyframes arrowRu {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(3px,3px);
      }
    }
  .lu
    animation: arrowLu 700ms ease-in-out infinite;
    @keyframes arrowLu {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(-3px,3px);
      }
    }
  .lo1
    animation: arrowLo1 700ms ease-in-out infinite;
    @keyframes arrowLo1 {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(3px,3px);
      }
    }
  .ro1
    animation: arrowRo1 700ms ease-in-out infinite;
    @keyframes arrowRo1 {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(-3px,3px);
      }
    }
  .ru1
    animation: arrowRu1 700ms ease-in-out infinite;
    @keyframes arrowRu1 {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(-3px,-3px);
      }
    }
  .lu1
    animation: arrowLu1 700ms ease-in-out infinite;
    @keyframes arrowLu1 {
      0%, 100%{
        transform: translate(0);
      }
      50% {
        transform: translate(3px,-3px);
      }
    }
</style>
