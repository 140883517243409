<template lang="html">
  <div class="textwrapper buzzword" :style="{ left:`${transformX}%`, top:`${transformY}%`}">
    <p>{{buzzwords}}</p>
  </div>
</template>

<script>
export default {
  props: {
    transformX: {
      type: Number,
      default: 0,
    },
    transformY: {
      type: Number,
      default: 0,
    },
    buzzwords: {
      type: String,
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'

.textwrapper
  // transform: translateX(40px);
  display: inline-block;
  align-items: flex-start;
  padding: 0px 7px 2px
  margin: 0
  margin-right: (8/16)rem
  background-color: $white
  color: $black
  border: 2px solid $black
  border-radius: 100px;
  font-family: 'SangBleuKingdom'
  font-style: italic;
  font-weight: normal
  font-size: 16px
  text-transform: lowercase;
  p
    margin 0
    margin-top: -2px
</style>
