export default {
  topic: 'preparation',
  number: 3,
  cardNumber: 3,
  title: 'heat and water',
  version: 1,
  updatedAt: '2020-12-22',
  thumbnail: {
    background: 'dots',
    graphic: 'cooking',
    buzzwords: ['aromacomponents', 'nose', 'tastebuds'],
  },
  content: [
    {
      title: 'basics',
      type: 'text',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
      links: ['temperature', 'heat'],
    },
    {
      title: 'chemical',
      type: 'image',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
      image: {
        src: 'chemical',
        caption: 'Functional Aroma Groups',
      },
    },
    {
      title: 'categories',
      type: 'table',
      text: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor  ',
      table: [
        {
          title: 'roasted',
          content: ['chockolade', 'coffee', 'peanut'],
        },
        {
          title: 'meaty',
          content: ['poultry', 'pork', 'lamb', 'beef', 'offal'],
        },
      ],
    },
    // {
    //   title: 'Aromacombinations',
    //   type: 'search',
    //   text: 'If you explain aroma we use different vocabulary compared with taste. It is possible to group different ingredients to sixteen different aroma categories. ',
    //   table: [
    //     {
    //       title: 'roasted',
    //       content: ['chockolade', 'coffee', 'peanut'],
    //     },
    //     {
    //       title: 'meaty',
    //       content: ['poultry', 'pork', 'lamb', 'beef', 'offal'],
    //     },
    //   ],
    // },
  ],
};
