export default {
  topic: 'taste',
  number: 1,
  cardNumber: 2,
  title: 'texture',
  version: 1,
  updatedAt: '2020-12-22',
  thumbnail: {
    background: 'dots',
    graphic: 'texture',
    buzzwords: ['aromacomponents', 'nose', 'tastebuds'],
  },
  content: [
    {
      title: 'tastebuds for sweetness',
      type: 'text',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,',
      links: ['sugar', 'salt', 'vergatbles', 'carbonhydrates'],
    },
    {
      title: 'Sweentness and Heat',
      type: 'text',
      text: 'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,',
      links: ['heat', 'maillard reaction', 'onions'],
    },
    // {
    //   title: 'Aromacombinations',
    //   type: 'search',
    //   text: 'If you explain aroma we use different vocabulary compared with taste. It is possible to group different ingredients to sixteen different aroma categories. ',
    //   table: [
    //     {
    //       title: 'roasted',
    //       content: ['chockolade', 'coffee', 'peanut'],
    //     },
    //     {
    //       title: 'meaty',
    //       content: ['poultry', 'pork', 'lamb', 'beef', 'offal'],
    //     },
    //   ],
    // },
  ],
};
