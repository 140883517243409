export default {
  topic: 'ingredients',
  number: 2,
  title: 'Balance',
  cardNumber: 3,
  version: 1,
  updatedAt: '2020-12-22',
  thumbnail: {
    background: 'dots',
    graphic: 'balance',
    buzzwords: ['aromacomponents', 'nose', 'tastebuds'],
  },
  content: [
    {
      title: 'taste of salt',
      type: 'text',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
      links: ['chemical', 'sweet', 'balance'],
    },
    {
      title: 'osmosis',
      type: 'image',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
      image: {
        src: 'saltOsmosis',
        caption: 'Osmosis through semipermeable membranes',
      },
    },
    // {
    //   title: 'Aromacombinations',
    //   type: 'search',
    //   text: 'If you explain aroma we use different vocabulary compared with taste. It is possible to group different ingredients to sixteen different aroma categories. ',
    //   table: [
    //     {
    //       title: 'roasted',
    //       content: ['chockolade', 'coffee', 'peanut'],
    //     },
    //     {
    //       title: 'meaty',
    //       content: ['poultry', 'pork', 'lamb', 'beef', 'offal'],
    //     },
    //   ],
    // },
  ],
};
