<template lang="html">
  <div class="button">
      <p class="inputText">{{buttonText}}</p>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'

.button
  // margin-right: (30/16)rem
  // max-width: (200/16)rem
  height: (50/16)rem
  // width: 100%
  display: inline-block;
  border  (4/16)rem solid
  border-color $black
  background-color: $white
  border-radius: 100px
  align-items: center;
  padding-left: (21/16)rem
  padding-right: (22/16)rem
  padding-bottom: (3/16)rem
  padding-top: (3/16)rem
  // line-height: 1
  transition: all 300ms ease
  &:hover
    cursor: pointer
    background-color: $red
    color: $white

  .inputText
    margin: 0 (12/16)rem
    font-family: 'SangBleuKingdom'
    font-size: (25/16)rem;
    font-style: italic;
    font-weight: normal
</style>
