<template lang="html">
  <div class="wrapper contentBlock">
    <div class="headwrapper">
      <p class="headNumber">{{`${number}.${index}.${entry + 1}`}}</p>
      <p class="header">{{`${topic.charAt(0).toUpperCase() + topic.slice(1)} → ${subtopic.charAt(0).toUpperCase() + subtopic.slice(1)} → ${title.charAt(0).toUpperCase() + title.slice(1)}`}}</p>
    </div>
    <p class="textContent">{{text}}</p>
    <!-- <pre>{{image}}</pre> -->
    <chefContentGraphics v-if="type === 'image'" :image="image && image.src"/>
    <p v-if="type === 'image'" class="figcaption"> {{`Abb1. ${image.src}`}} </p>
    <div class="buzzwords"  v-if="type === 'text'">
      <chefBuzzword v-for="(buzzwords,index) in links" :key="index" :buzzwords="links[index]"/>
    </div>
    <!-- <contentTable v-if="content.type === table" :content="content"/> -->
    <!-- <template v-for="content in contentblocks"></template> -->
  </div>
</template>

<script>
import ChefContentGraphics from '@/components/ChefContentGraphics.vue';
import ChefBuzzword from '@/components/ChefBuzzword.vue';

export default {
  components: {
    ChefContentGraphics,
    ChefBuzzword,
  },
  props: {
    topic: {
      type: String,
      default: 'Lorem',
    },
    text: {
      type: String,
    },
    subtopic: {
      type: String,
      default: 'ipsum',
    },
    title: {
      type: String,
      default: 'dolor',
    },
    image: {
      type: Object,
    },
    type: {
      type: String,
      default: 'image',
    },
    links: Array,
    table: Array,
    number: Number,
    index: Number,
    entry: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'
.wrapper
    max-width: (700/16)rem;
    width: 100%
    background-color: $white
    margin-bottom: (30 / 16)rem
  .figcaption
    margin: 0
    margin-left: (90/16)rem
    font-size: (14/16)rem
  .buzzwords
    margin-left: (90/16)rem
    margin-top: (20/16) rem
    .buzzword:hover
      background-color: $red
      color: $white
      cursor: pointer;

  .contentImage
    max-width: (561/16)rem;
    width:100%;
    height:100%
    margin-left: (90/16)rem
    margin-top: (25/16)rem
  .headwrapper
    color: $black;
    display: flex;
    .headNumber
      font-family: 'SangBleuKingdom'
      font-size: (14/16)rem;
      max-width: (79/16)rem;
      width: 100%
      text-align: right;
      padding-right: (20/16)rem

    .header
      font-family: 'SangBleuKingdom'
      font-size: (14/16)rem;
      // padding: 0
      padding-left: (11/16)rem;
  .textContent
    padding-left: (90/16)rem
    padding-right: (40/16)rem
    font-size: (18/16)rem
    font-family: 'SuisseIntl'
    margin-top: 0
    margin-bottom: 0
</style>
