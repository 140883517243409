<template lang="html">
  <transition :class="{transition: !dragging, snapToGrid: !$store.state.chaos && !dragging && oncedragged, drag: dragging}">
    <div class="wrapper card" :style="{transform: `translate(${dragX}px, ${dragY}px)`, zIndex: currentZindex}" @pointerdown="bringToTop">
      <!-- Die Zahl muss von außen durch die Datenstruktur eingegeben werden -->
      <div class="cardwrapper" :class="{active: hovered}">
        <chefHeader @hover="hovered = true" @hoverleave="hovered = false" @pointerdown="dragStart" :topic="content.topic" :subTopic="content.title" :edited="content.updatedAt" :version="content.version" :header="content.title" @toggleexpand="changeState" :iconstate="expanded" :number="content.number" :index="content.cardNumber"/>
        <transition>
          <div class="content" :class="{zooming: $store.state.zoom}" v-show="expanded" @scroll.passive="handleScroll" @mouseover="startAnimation = true; showScrollIndicator = true" @mouseleave="startAnimation = false; showScrollIndicator = false">
            <transition name="showIndicator">
              <p class="scrollIndicator" v-if="showScrollIndicator && !scrolled">Scroll to explore</p>
            </transition>
            <div class="scrollBox" :style="{transform: `translateY(${transformY}px)`}" ref="trigger">
              <chefThumb  :background="content.thumbnail.background" :startAnimation="startAnimation" :topic="content.thumbnail.graphic" :buzzwords="content.thumbnail.buzzwords"/>
              <chefContentblock v-for="(block, entry) in content.content" :key="block.title" :topic="content.topic" :subtopic="content.title" :title="block.title" :type="block.type" :text="block.text" :links="block.links" :image="block.image" :table="block.table" :number="content.number" :index="content.cardNumber" :entry="entry"/>
              <div class="bottomspacer"></div>
            </div>
          </div>
        </transition>
      </div>
      <div class="arrowsExpanded" v-show="expanded" :class="{active: hovered, faded: dragging}">
        <svg viewBox="0 0 987 756" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M64.397 437.147L5.00003 377.75L64.397 318.353" stroke="#362020" stroke-width="6"/>
          <path d="M4.99999 377.75L153.5 377.75" stroke="#362020" stroke-width="6"/>
          <path d="M923.103 318.353L982.5 377.75L923.103 437.147" stroke="#362020" stroke-width="6"/>
          <path d="M982.5 377.75L834 377.75" stroke="#362020" stroke-width="6"/>
          <path d="M552.147 692.103L492.75 751.5L433.353 692.103" stroke="#362020" stroke-width="6"/>
          <path d="M492.75 751.5L492.75 603" stroke="#362020" stroke-width="6"/>
          <path d="M433.353 64.3969L492.75 5L552.147 64.397" stroke="#362020" stroke-width="6"/>
          <path d="M492.75 4.99999L492.75 153.5" stroke="#362020" stroke-width="6"/>
        </svg>
      </div>
      <div class="arrows" v-show="!expanded" :class="{active: hovered, faded: dragging}">
        <svg viewBox="0 0 934 323" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M64.397 223.647L5.00003 164.25L64.397 104.853" stroke="#362020" stroke-width="6"/>
          <path d="M4.99999 164.25L153.5 164.25" stroke="#362020" stroke-width="6"/>
          <path d="M870.103 105.853L929.5 165.25L870.103 224.647" stroke="#362020" stroke-width="6"/>
          <path d="M928.5 165.25L780 165.25" stroke="#362020" stroke-width="6"/>
          <path d="M520.647 259.103L461.25 318.5L401.853 259.103" stroke="#362020" stroke-width="6"/>
          <path d="M461.25 319.5L461.25 171" stroke="#362020" stroke-width="6"/>
          <path d="M401.853 64.3969L461.25 5L520.647 64.397" stroke="#362020" stroke-width="6"/>
          <path d="M461.25 4.99999L461.25 153.5" stroke="#362020" stroke-width="6"/>
        </svg>
      </div>
    </div>
  </transition>
</template>
<script>
import ChefHeader from '@/components/ChefHeader.vue';
import ChefThumb from '@/components/ChefThumb.vue';
import ChefContentblock from '@/components/ChefContentblock.vue';

export default {
  watch: {
    chaos(newValue) {
      if (newValue) {
        this.dragX = this.cachePosition.x;
        this.dragY = this.cachePosition.y;
      } else {
        this.oncedragged = false;
        this.cachePosition.x = this.dragX;
        this.cachePosition.y = this.dragY;
        this.dragX = this.normalizeValue(this.$store.state.gridPositions[this.index].x);
        this.dragY = this.$store.state.gridPositions[this.index].y;
      }
    },
  },
  computed: {
    chaos() {
      return this.$store.state.chaos;
    },
    transformX() {
      if (this.$store.state.chaos) {
        return this.dragX;
      }
      return this.$store.state.gridPositions[this.index].x;
    },
    transformY2() {
      if (this.$store.state.chaos) {
        return this.dragY;
      }
      return this.$store.state.gridPositions[this.index].y;
    },
  },
  mounted() {
    // console.log('card mounted');
    const distanceFromCenter = (600 / 16) * this.$store.state.rembase;
    const randomFactor = (400 / 16) * this.$store.state.rembase;
    // // console.log(this.content.content);
    // Karten anhand des Themas anordnen
    if (this.content.topic === 'taste') {
      this.dragX = this.normalizeValue((this.$store.state.normalX - (Math.random() * randomFactor)) - distanceFromCenter);
      this.dragY = this.normalizeValue((this.$store.state.normalY - (Math.random() * randomFactor)) - distanceFromCenter);
    }
    if (this.content.topic === 'ingredients') {
      this.dragX = this.normalizeValue((this.$store.state.normalX - (Math.random() * randomFactor)));
      this.dragY = this.normalizeValue((this.$store.state.normalY - (Math.random() * randomFactor)) + distanceFromCenter);
    }
    if (this.content.topic === 'preparation') {
      this.dragX = this.normalizeValue((this.$store.state.normalX - (Math.random() * randomFactor)) + distanceFromCenter);
      this.dragY = this.normalizeValue((this.$store.state.normalY - (Math.random() * randomFactor)) - distanceFromCenter);
    }
    this.startX = this.dragX;
    this.startY = this.dragY;
    this.lastY = this.dragY;
    this.lastX = this.dragX;

    this.currentZindex = (this.$store.state.numberOfCards) - this.index;
    // Set first Card Position to Store;
    if (this.index === 0) {
      this.$store.commit('setFirstCardX', this.dragX * (-1));
      this.$store.commit('setFirstCardY', this.dragY * (-1));
      // console.log(this.dragX);
      // console.log(this.dragY);
    }
  },
  components: {
    ChefHeader,
    ChefThumb,
    ChefContentblock,
  },
  data() {
    return {
      oncedragged: false,
      startAnimation: false,
      showScrollIndicator: false,
      momentumID: 0,
      velX: 0,
      velY: 0,
      cachePosition: { x: 0, y: 0 },
      currentZindex: 0,
      dragX: 0,
      stratX: 0,
      dragY: 0,
      startY: 0,
      dragging: false,
      hovered: false,
      scrolled: false,
      expanded: true,
      transformY: 0,
      newScroll: true,
      topic: '',
    };
  },
  methods: {
    normalizeValue(value) {
      return ((50 / 16) * this.$store.state.rembase) * Math.floor(value / ((50 / 16) * this.$store.state.rembase));
    },
    bringToTop() {
      if (this.$store.state.pressedKey === ' ') return;
      this.$store.commit('setHighestIndex');
      this.currentZindex = this.$store.state.highestIndex;
    },
    dragStart(e) {
      if (this.$store.state.pressedKey === ' ') return;
      // console.log('das sollte nicht ausgeführt werden');
      // this.dragX = e.clientX - this.startX;
      // this.dragY = e.clientY - this.startY;
      this.startX = e.clientX;
      this.startY = e.clientY;
      // // console.log(this.startX);
      // // console.log(this.startY);

      if (e.target.classList.contains('iconWrapper') || e.target.classList.contains('icon')) return;
      window.addEventListener('mousemove', this.dragUpdate, false);
      window.addEventListener('mouseup', this.dragEnd, false);
      // console.log('start Dragging:');
      // console.log(this.dragX);
      // console.log(this.dragY);
      this.dragging = true;
    },
    dragUpdate(e) {
      this.oncedragged = true;
      if (this.$store.state.pressedKey === ' ') return;
      const deltaX = (e.clientX - this.startX) / (this.$store.state.scaleFactor / 100);
      const deltaY = (e.clientY - this.startY) / (this.$store.state.scaleFactor / 100);
      this.startX = e.clientX;
      this.startY = e.clientY;
      // const moveX = this.lastX + deltaX;
      // const moveY = this.lastY + deltaY;
      if (!this.$store.state.chaos) {
        this.dragX += Math.round(deltaX);
        this.dragY += Math.round(deltaY);
      } else {
        this.dragX += deltaX;
        this.dragY += deltaY;
        this.velX = deltaX;
        this.velY = deltaY;
      }
    },
    dragEnd() {
      window.removeEventListener('mousemove', this.dragUpdate, false);
      window.removeEventListener('mouseup', this.dragEnd, false);
      this.dragging = false;

      // Upadate Ordered Array at Store when in Ordermode
      if (!this.$store.state.chaos) {
        // Ordermode Snapping
        const gridSize = (50 / 16) * this.$store.state.rembase;
        const snapTolerance = gridSize / 2;
        // Calculate Next position in the Grid when snapped
        const snapCandidateX = gridSize * Math.floor(this.dragX / gridSize);
        const snapCandidateY = gridSize * Math.floor(this.dragY / gridSize);
        // console.log('There are the Current Values for X');
        // console.log(`Drag X${this.dragX}`);
        // console.log(`NextGridstep in X${snapCandidateX}`);
        // console.log(`Distance between in X${this.dragX - snapCandidateX}`);

        if (Math.abs(this.dragX - snapCandidateX) <= snapTolerance) {
          this.dragX = snapCandidateX;
          // console.log('Go right');
        }
        if (Math.abs(this.dragX - snapCandidateX) > snapTolerance) {
          this.dragX = snapCandidateX + gridSize;
          // console.log('Go left');
        }
        if (Math.abs(this.dragY - snapCandidateY) <= snapTolerance) {
          this.dragY = snapCandidateY;
        }
        if (Math.abs(this.dragY - snapCandidateY) > snapTolerance) {
          this.dragY = snapCandidateY + gridSize;
        }
        const positionArray = this.$store.state.gridPositions;
        positionArray[this.index].x = this.dragX;
        positionArray[this.index].y = this.dragY;
        this.$store.commit('setGrid', positionArray);
      }
    },
    handleScroll(e) {
      this.scrolled = true;
      if (e.target.scrollTop === 0) {
        this.scrolled = false;
      }
    },
    changeState() {
      if (this.dragging) {
        return;
      }
      this.expanded = !this.expanded;
      // console.log(this.expanded);
    },
  },
  props: {
    content: {
      type: Object,
    },
    index: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'
.drag
  cursor: grab;
.transition
  transition: transform 900ms ease-out;

.snapToGrid
  transition: transform 80ms ease

.wrapper.card
  position: absolute;
  left: 100px
  top: 100px
  display: inline-block;
  max-width: (700/16)rem
  width: 100%
  .header, #parent
    cursor: pointer
  .arrows
    width: (934/16)rem
    height: (323/16)rem
    display: inline-block;
    position: absolute;
    color red
    left: "calc(50% - %s)" % ((934/2)/16)rem
    top: "calc(50% - %s)" % ((323/2)/16)rem
    z-index: -1
    transition: transform 100ms ease-in,  opacity 500ms ease
    transform: scale(0)
    &.active
      transform: scale(1)
    &.faded
      opacity: 0
  .arrowsExpanded
    display: inline-box;
    width: (987/16)rem
    height: (756/16)rem
    position: absolute;
    color red
    left: "calc(50% - %s)" % ((987/2)/16)rem
    top: "calc(50% - %s)" % ((756/2)/16)rem
    z-index: -1
    transition: transform 100ms ease-in, opacity 500ms ease
    transform: scale(0.6)
    &.active
      transform: scale(1)
    &.faded
      opacity: 0;
  .content
    max-width: (700/16)rem
    height: (390/16)rem
    overflow: scroll;
    border-left: $borderBoxes
    border-right: $borderBoxes
    border-bottom: $borderBoxes
    border-color $black
    background-color: $white;
    transition: 2s ease;
    .scrollIndicator
      pointer-events: none
      position: absolute;
      display: inline-block;
      width: (180/16)rem
      padding: (5/16)rem 0
      border  (3/16)rem solid
      border-radius: 100px
      border-color $black
      background-color: $white
      font-family: 'SangBleuKingdom'
      font-size: (15/16)rem;
      font-style: italic;
      font-weight: normal
      text-align: center;
      left: "calc(50% - %s)" % ((180/2)/16)rem
      top: (380/16)rem
      z-index: 200;
    &.zooming
      overflow: hidden;

    .scrollBox
      transition: transform 150ms ease-in
      background-color: $white
      &:hover
        // transform: translateY(-100px)
      .placeholder
        text-align: center

    &.v-enter-active,
    &.v-leave-active
      transition: height 250ms ease-in
      // transition: transform 500ms ease
      // transform-origin: top

      &.v-enter-from,
      &.v-leave-to
        height: 0
        // transform: scaleY(0)
    .bottomspacer
      height: (30/16)rem
  .cardwrapper
    box-shadow: (0/16)rem (0/16)rem (0/16)rem rgba(54, 32, 32, 0.2);
    transition: box-shadow 250ms cubic-bezier(0.34, 1.56, 0.64, 1);
  .cardwrapper.active
    box-shadow: $dragShadow;

.showIndicator-enter-active,
.showIndicator-leave-active
  transition: opacity 100ms ease, transform 200ms ease
  // transform-origin: top
.showIndicator-enter-from,
.showIndicator-leave-to
   opacity: 0
   transform: translateY((30/16)rem);
</style>
