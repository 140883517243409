<template lang="html">
    <div class="onboarding">
      <div class="background"></div>
      <div class="contentWrapper">
        <div class="content">
          <h1>Welcome to Chef's Memory</h1>
          <h2>A visual experiment on the science behind cooking. </h2>
          <chefLottie id="animationContainer" class="anim" :animation="topic[topicCount].anim" :startAnimation="true"/>
          <transition name="morph">
            <p class="animCaption">{{topic.[topicCount].text}}</p>
          </transition>
          <chefButtonRounded :buttonText="topic.[topicCount].button"  @click="handleOnboardind"/>
        </div>
      </div>
    </div>
</template>

<script>
import ChefLottie from '@/components/ChefLottie.vue';
import ChefButtonRounded from '@/components/ChefButtonRounded.vue';

export default {

  methods: {
    handleOnboardind() {
      this.topicCount += 1;
      console.log(this.topicCount);
      if (this.topicCount === 4) {
        localStorage.setItem('onboardingComplete', 'true');
        this.$store.commit('stopOnboarding');
      }
    },
    // changeTopic() {
    //   const animations = document.getElementById('animationContainer');
    //   console.log(typeof animations);
    //   while (animations.firstChild) {
    //     animations.removeChild(animations.lastChild);
    //   }
    //   this.topicCount += 1;
    // },
  },
  components: {
    ChefLottie,
    ChefButtonRounded,
  },
  data() {
    return {
      topicCount: 0,
      topic: [
        { anim: 'move', text: 'Drag the cards around using your mouse.', button: 'Got it!' },
        { anim: 'expand', text: 'You can minimize a card if you click in the top right corner.', button: 'Ahh!' },
        { anim: 'scroll', text: 'While your mouse rests over a card you can scroll the content.', button: 'Great!' },
        { anim: 'nav', text: 'Use the Spacebar to drag the window. Use the Command or Control key in combination with scrolling to zoom in and out.', button: 'Nice!' },
        { anim: 'nav', text: 'Use the Spacebar to drag around the window. Use the the command or Control key together with your scrolling to zoom in and out.', button: 'Nice!' },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'
.morph-enter-active,
.morph-leave-active
  transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1), opacity 300ms ease
  // transition: transform 500ms ease
  // transform-origin: top
.morph-enter-from,
.morph-leave-to
   transform: translateY(500px);
   opacity: 0

.onboarding
  position: absolute;
  z-index: 200
  width: 100%
  height: 100%
  .background
    background-color: rgba(252, 251, 245, 0.98)
    position: absolute;
    z-index: 199
    width: 100%
    height: 100%
    top: 0
    left: 0
  .contentWrapper
    text-align: center
    background-color: $white
    position: fixed;
    z-index: 200;
    max-width: (1200/16) rem
    max-height: (700/16) rem
    left: "calc(50% - %s)" % ((1200/2)/16)rem
    top: "calc(50% - %s)" % ((700/2)/16)rem
    // border: $borderBoxes
    width: 100%
    height: 100%
    padding: 3rem 3rem
    .content
      .anim
        width: (500/16)rem
        margin: (-30/16)rem auto
      .drag
        margin-top: 1.5 rem
        width: (400/16) rem
      h1
        font-family: 'SangBleuKingdom'
        font-size: (42/16)rem;
        font-weight: bold
        padding: 0
        margin: (8/16)rem 0

      h2
        font-family: 'SangBleuKingdom'
        font-weight: normal
        font-size: (25/16)rem;
        margin: 0 auto
        max-width: (500/16)rem
      .button
        margin-top: (30/16)rem
        margin-right: 0
        transition: width 200ms ease

    .animCaption
      max-width: (500/16)rem
      margin: 0 auto
      font-family: 'SuisseIntl'
      font-size: (16/16)rem;
      color: #A39A9A;

</style>
