<template lang="html">
  <div class="wrapper thumb">
    <!-- <chefLottie class="hg" :animation="background" :startAnimation="startAnimation"/> -->
    <ChefBackgrounds class="hg" :background="background"/>
    <chefGraphics class="fg" :topic="topic"/>
    <!-- <chefBuzzword class="buzz" v-for="(buzzword, index) in buzzwords" :key="index" :buzzwords="buzzword" :transformY="Math.random() * 80" :transformX="Math.random() * 80"/> -->
  </div>
</template>

<script>
import ChefGraphics from '@/components/ChefGraphics.vue';
import ChefBackgrounds from '@/components/ChefBackgrounds.vue';
// import ChefLottie from '@/components/ChefLottie.vue';
// import ChefBuzzword from '@/components/ChefBuzzword.vue';

export default {
  mounted() {
    // console.log('ChefThumb startAnimation');
    // console.log(this.startAnimation);
  },
  components: {
    ChefGraphics,
    ChefBackgrounds,
    // ChefLottie,
    // ChefBuzzword,
  },
  props: {
    startAnimation: Boolean,
    topic: {
      type: String,
      default: 'aroma',
    },
    background: {
      type: String,
      default: 'wave',
    },
    buzzwords: {
      type: Array,
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
.wrapper
  // width: (700/16)rem;
  // height: (390/16)rem;
  background-color: $white
  position: relative;
  overflow: hidden
  .hg
    // position: absolute;
    width: 100%
    height @width
    top: 0;
    left:0;
    z-index: 0;
  .fg
    width: 700px
    height auto
    position: absolute;
    left:0
    top:0;
    z-index: 1;
  .buzz
    position: absolute;
    z-index: 3;
</style>
