<template lang="html">
  <transition name="background">
    <div class="background" v-show="$store.state.toggleAbout" @click.self="closeAbout">
      <transition name="toggle">
        <div v-show="$store.state.toggleAbout" class="about">
          <div class="wrapperBox">
            <div class="iconWrapper" @click="closeAbout">
            </div>
            <div class="headWrapper">
              <p class="subheadline">Patrick Jürgens → About the project</p>
              <!-- <p class="subheadline">{{'Taste → Aroma – Last Edited 22-12-2020 – Version 003'}}</p> -->
              <h2 class="headline">Chef's Memory</h2>
            </div>
            <p class="number">0.0</p>
          </div>
          <div class="contentBlock">
            <div class="textContent">
              <p class="head"> A visual experience on the science behind cooking.</p>
              <p>The visual result of general research for the bachelor thesis.</p>
              <p>Coded with Vue3 by Patrick Jürgens. Communication design generalist, frontend developer and motion designer currently studying in his last term at UAS Munich.</p>
              <p>If you got curios check out <a target="_blank" href="https://p-juergens.com">my website.</a></p>
              <p>Credits: <br> Nosrat, S. (2017). “Salt, Fat, Acid, Heat: Mastering the Elements of Good Cooking” (3rd. ed.). Simon & Schuster, New York</p>
              <p>Special thanks to <a target="_blank" href="https://amxmln.com">Amadeus Stadler</a>!</p>
          </div>
        </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
// import ChefContentblock from '@/components/ChefContentblock.vue';

export default {
  components: {
    // ChefContentblock,
  },
  data() {
    return {
      // aboutText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et   justo.',
      toggleactive: true,
    };
  },
  methods: {
    closeAbout() {
      this.$store.commit('toggleAbout');
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'
.background
  background-color: rgba(252, 251, 245, 0.9)
  position: absolute;
  z-index: 199
  width: 100%
  height: 100%
  top: 0
  left: 0
  .about
    height: auto
    .contentBlock
        max-width: (700/16)rem;
        width: 100%
        height: 100%
        background-color: $white
        border-left: $borderBoxes
        border-right: $borderBoxes
        border-bottom: $borderBoxes
      .headwrapper
        color: $black;
        display: flex;
        .headNumber
          font-family: 'SangBleuKingdom'
          font-size: (14/16)rem;
          max-width: (79/16)rem;
          width: 100%
          text-align: right;
          padding-right: (20/16)rem

        .header
          font-family: 'SangBleuKingdom'
          font-size: (14/16)rem;
          // padding: 0
          padding-left: (11/16)rem;
      .textContent
        padding-left: (90/16)rem
        padding-right: (40/16)rem
        font-family: 'SuisseIntl'
        margin-top: 0
        margin-bottom: 2rem
        p
          margin-top: 0
          font-size: (15/16)rem
          a
            text-decoration: none
            color: $red
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 2px;
            transition: background-size 250ms ease-in ;
            &:hover
              background-size: 100% 2px;
        .head
          padding-top: 1.5rem
          font-size: (23/16)rem

    .wrapperBox
      display: flex;
      align-items: center
      max-width: (700/16)rem
      width:100%
      border $borderBoxes
      border-color $black
      background-color: $white
      padding-right: (10/16)rem
      line-height: 1
      .iconWrapper
        flex-shrink: 0
        cursor: pointer;
        display: flex
        align-items: center
        justify-content: center
        width: (79 / 16)rem
        height: @width
        border-right: $borderBoxes
        border-color: $black
        height (79/16)rem;

      .headWrapper
        flex-shrink: 1
        width:100%
        padding-left: (10/16)rem
        padding-right: (24/16)rem
        overflow: hidden;
        .subheadline
          font-family: 'SangBleuKingdom'
          margin: 0;
          margin-bottom: (8/16)rem
          font-size: (13/16)rem;
          white-space: nowrap
          text-overflow: ellipsis
          overflow: hidden
        .headline
          font-family: 'SangBleuKingdom'
          font-size: (37/16)rem;
          text-transform: uppercase;
          font-weight: bold;
          margin: 0;
          white-space: nowrap
          text-overflow: ellipsis
          overflow: hidden
      .number
        flex-shrink: 0
        margin: 0;
        text-align: right
        font-family: 'SangBleuKingdom'
        font-size: (76/16)rem

.toggle-enter-active,
.toggle-leave-active
  transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1), opacity 300ms ease
  // transition: transform 500ms ease
  // transform-origin: top
.toggle-enter-from,
.toggle-leave-to
   transform: translateY(500px);
   opacity: 0
.background-enter-active,
.background-leave-active
  transition: opacity 400ms ease
  // transition: transform 500ms ease
  // transform-origin: top
.background-enter-from,
.background-leave-to
   opacity: 0

</style>
