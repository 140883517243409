<template>
  <div id="nav" v-if="!$store.state.onboarding">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
    <!-- <p class="scrollIndicator"> {{`Under construction; Zoom: ${Math.round($store.state.scaleFactor)}%`}}</p> -->

    <!-- <input type="range" min="1" max="100" value="50" class="slider" v-model="sliderValue"> -->
    <!-- <chefAbout/> -->
    <!-- <button @click="orderChaos" type="button" name="button">Ordnen</button> -->
  </div>
  <router-view/>
</template>
<script>
// import ChefButtonRounded from '@/components/ChefButtonRounded.vue';
// import ChefAbout from '@/components/ChefAbout.vue';

export default {
  components: {
    // ChefButtonRounded,
    // ChefAbout,
  },
  computed: {
    sliderValue() {
      return 50;
    },
  },
  created() {
    if (window.innerWidth < 400 || this.isTouchDevice()) {
      console.log(this.isTouchDevice());
      console.log('TouchDevice');
      this.$store.commit('setMobileState', true);
    } else {
      const onboardingComplete = localStorage.getItem('onboardingComplete');
      if (onboardingComplete === 'true') this.$store.commit('stopOnboarding');
      this.$store.commit('setMobileState', false);
    }
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) console.log('mobile Device');
  },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    this.$store.commit('setRemBase', Number.parseInt(window.getComputedStyle(document.documentElement).fontSize, 10));
    window.addEventListener('keydown', (e) => {
      const keyName = e.key;
      console.log(keyName);
      this.$store.commit('setPressedKey', keyName);
      if (keyName === ' ') e.preventDefault();
    });
    window.addEventListener('keyup', () => {
      // console.log('released');
      this.$store.commit('setPressedKey', '');
      this.$store.commit('setZoom', false);
    });
  },
  methods: {
    isTouchDevice() {
      return ((typeof window.ontouchstart !== 'undefined') || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    },
  },
};
</script>
<style lang="stylus">

@require 'styles/styles.styl'
// @require '@/styles/fonts.styl'

.scrollIndicator
  margin-right: (50/16)rem
  position: fixed
  top: 1.5rem
  left:  3rem
  color: $black

.button
  margin-right: (20/16)rem

#app
  overflow: hidden;
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  overflow: hidden;

#nav
  position: fixed
  bottom: (20/16)rem
  right: (20/16)rem
  display: flex
  z-index: 1

</style>
