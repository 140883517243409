<template lang="html">
  <div class="wrapperBox header">
    <div class="iconWrapper" @click="$emit('toggleexpand')">
      <chefIcon class="icon" :icon="checkstate(iconstate)"/>
    </div>
    <div class="headWrapper" @mouseover="$emit('hover')" @mouseleave="$emit('hoverleave')">
        <p class="subheadline">{{`${topic.charAt(0).toUpperCase() + topic.slice(1)} → ${subTopic.charAt(0).toUpperCase() + subTopic.slice(1)}`}}</p>
        <!-- <p class="subheadline">{{`${topic} → ${subTopic} → Last Edited ${edited} → Version 00${version}`}}</p> -->
        <!-- <p class="subheadline">{{'Taste → Aroma – Last Edited 22-12-2020 – Version 003'}}</p> -->
        <h2 class="headline">{{header}}</h2>
    </div>
      <p  @mouseover="$emit('hover')" @mouseleave="$emit('hoverleave')" class="number">{{`${number}.${index}`}}</p>
  </div>
</template>

<script>
import ChefIcon from '@/components/ChefIcon.vue';

export default {
  data() {
    return {
      expanded: false,
    };
  },
  components: {
    ChefIcon,
  },
  methods: {
    checkstate(iconstate) {
      if (iconstate) {
        return 'close';
      }
      return 'open';
    },
  },
  props: {
    iconstate: Boolean,
    number: Number,
    index: Number,
    topic: {
      type: String,
    },
    subTopic: {
      type: String,
    },
    edited: {
      type: String,
    },
    version: {
      type: Number,
    },
    header: {
      type: String,
    },
    head: {
      type: String,
      default: 'Lorem ipsum',
    },
    icon: {
      type: String,
      default: 'fail',
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/styles.styl'
@require '../styles/fonts.styl'
.wrapperBox
  display: flex;
  align-items: center
  max-width: (700/16)rem
  width:100%
  border $borderBoxes
  border-color $black
  background-color: $white
  padding-right: (10/16)rem
  color: $black
  line-height: 1
  .iconWrapper
    flex-shrink: 0
    cursor: pointer;
    display: flex
    align-items: center
    justify-content: center
    width: (79 / 16)rem
    height: @width
    border-right: $borderBoxes
    border-color: $black
    height (79/16)rem;

  .headWrapper
    flex-shrink: 1
    width:100%
    padding-left: (10/16)rem
    padding-right: (24/16)rem
    overflow: hidden;
    .subheadline
      font-family: 'SangBleuKingdom'
      margin: 0;
      margin-bottom: (8/16)rem
      font-size: (13/16)rem;
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    .headline
      font-family: 'SangBleuKingdom'
      font-size: (37/16)rem;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0;
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
  .number
    flex-shrink: 0
    margin: 0;
    text-align: right
    font-family: 'SangBleuKingdom'
    font-size: (76/16)rem
</style>
